<template>
  <ASection style="margin-top: -13px;" class="main-section pl-0 pr-0 pt-3" id="main-section">
    <main class="main" id="main">
      <ASectionTitle class="mb-6">
        <template v-slot:icon>
          <div>
            <img src="../../assets/fale_conosco_v2.svg" alt="Ícone fale conosco" />
          </div>
        </template>
        <template v-slot:title>
          <div style="color: #70CDED;">
            Fale Conosco
          </div>
        </template>
      </ASectionTitle>
      <AColumns class="is-gapless is-centered is-multiline px-4">
        <AColumn class="is-10">
          <AColumns class="
              fale-box
              is-centered
              is-gapless
              is-mobile
              is-multiline
              is-vcentered
              is-uppercase
              rounded
            ">
            <AColumn
              class="fale-label-box has-text-centered-mobile has-text-right-desktop is-full-mobile is-full-tablet is-half-desktop is-flex is-align-items-center is-justify-content-center">
              <div>
                <div class="px-2 py-4 is-size-4-desktop fale-label">
                  Entre em contato com nossa<br />
                  central de atendimento
                </div>
                <div class="px-2 py-4 is-size-7-desktop fale-label" style="text-transform: none;">
                  De segunda à sexta das 8hrs às 20hrs <br />
                  (exceto em feriados nacionais)
                </div>
              </div>
            </AColumn>
            <AColumn class="has-text-centered is-full-mobile is-full-tablet is-half-desktop has-text-white">
              <AColumns class="
                  is-centered is-gapless is-mobile is-multiline is-vcentered
                  has-text-left">
                <AColumn class="is-full fale-row"
                  style="background-color: #7578DD; display: flex; justify-content: center; align-items: center; height: 130px;">
                  <div class="fale-img pr-5">
                    <img src="../../assets/Grupo55.svg" alt="osteoporose"/>
                  </div>
                  <div class="fale-telefone">
                    <p class="is-size-7" style="font-weight: 700;">Telefone</p>
                    <p> 0800 264 0001</p>
                  </div>
                </AColumn>
                <AColumn class="is-full fale-row"
                  style="background-color: #7D80DE; display: flex; justify-content: center; align-items: center; height: 130px;">
                  
                    <div class="fale-img pr-5">
                      <a target="_blank" style="color: white;" href="https://wa.me/558001030420">
                        <img src="../../assets/Grupo56.svg" alt="cancer coloretal"/>
                      </a>
                    </div>
                    <div class="fale-telefone">
                      <a target="_blank" style="color: white;" href="https://wa.me/558001030420">
                        <p class="is-size-7" style="font-weight: 700;">WhatsApp</p>
                        <p>0800 103 0420</p>
                    </a>
                    </div>
             
                </AColumn>
                <AColumn class="is-full fale-row"
                  style="background-color: #888BE5; display: flex; justify-content: center; align-items: center; flex-direction: column; height: 130px;">
                  <div class="fale-telefone" style="margin-bottom: 10px;">
                    <p class="is-size-7" style="font-weight: 700;">Baixe o App</p>
                  </div>
                  <div class="fale-row-images" style="display: flex; justify-content: center; align-items: center;">
                    
                    <div class="fale-img pr-5">
                      <a target="_blank" href="https://apps.apple.com/br/app/programa-de-paciente-bemperto/id6444049895">
                        <img src="../../assets/Imagem14.png" alt="leucemia" style="height: 40px;" />
                      </a>
                    </div>

                    <div class="fale-img pr-5">
                        <a target="_blank" href="https://play.google.com/store/apps/details?id=com.mysalesforce.mycommunity.C00D46000000ahY2EAI.A0OT2B0000000007WAA">
                          <img src="../../assets/Imagem15.png" alt="leucemia" style="height: 34px;" />
                        </a>
                    </div>

                  </div>
                </AColumn>
              </AColumns>
            </AColumn>
          </AColumns>
        </AColumn>
        <!-- <AColumn v-if="!storeLink" class="has-text-centered is-full is-size-7 mt-3">
          Baixe o app e conheça mais sobre os programas.
        </AColumn>
        <AColumn v-else class="has-text-centered is-full is-size-7 mt-3">
          <a :href="storeLink" target="_blank" title="Ir para a Loja de Aplicativos">
            Baixe o app e conheça mais sobre os programas.
          </a>
        </AColumn> -->
        <AColumn class="has-text-centered is-full is-flex is-align-items-center is-justify-content-center mt-4">
          <div>
            <a href="javascript:history.back();" class="backlink">Voltar</a>
          </div>
        </AColumn>
      </AColumns>
    </main>
  </ASection>
</template>

<script>
import ASection from "@/components/atoms/Section.vue";
import AColumns from "@/components/atoms/Columns.vue";
import AColumn from "@/components/atoms/Column.vue";
import ASectionTitle from "@/components/molecules/SectionTitle.vue";
import { onMounted, ref } from "vue";

export default {
  components: {
    ASection,
    ASectionTitle,
    AColumns,
    AColumn
  },
  name: "OMainFaleConosco",
  setup() {
    const storeLink = ref("");

    onMounted(() => {
      const userAgent = navigator.userAgent.toLowerCase();

      if (/android/g.test(userAgent)) {
        storeLink.value =
          "https://play.google.com/store/apps/details?id=com.mysalesforce.mycommunity.C00D46000000ahY2EAI.A0OT2B0000000007WAA";
      }

      if (/ip[hone|ad]/g.test(userAgent)) {
        storeLink.value =
          "https://apps.apple.com/br/app/programa-de-paciente-bemperto/id6444049895";
      }
    });

    return { storeLink };
  }
};
</script>

<style lang="scss">
@import "~bulma/sass/helpers/color.sass";
@import "~bulma/sass/helpers/spacing.sass";
@import "~bulma/sass/helpers/flexbox.sass";

.fale-img {
  margin-bottom: 8px;

  @include media(">=tablet") {
    display: inline-block;
    margin-bottom: 0;
    vertical-align: middle;
  }
}

.fale-telefone {
  display: inline-block;
  vertical-align: middle;
}

.rounded {
  border-radius: 4px;
  overflow: hidden;
}

.fale-box {
  background-color: white;
  color: #6669CC;

  @include media(">=desktop") {
    background-color: #6669CC;
    color: white;
  }
}

.fale-label {
  font-weight: bold;

  @include media(">=desktop") {
    font-weight: normal;
  }
}

.fale-label-box {
  border: 1px solid #0575be;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  @include media(">=desktop") {
    border: 0;
    border-radius: 0;
  }
}

.fale-row.column.is-full {
  padding: 16px !important;
}

.backlink {
  &:hover {
    text-decoration: underline;
  }
}
</style>
